import {useStaticQuery, graphql} from "gatsby";

export const useSiteContent = () => {
  const { siteContent } = useStaticQuery(graphql`
              query SiteContentData {
                  siteContent {
                      id
                      areas_esp {
                          label_1
                          label_2
                          label_3
                      }
                      contact {
                          lat
                          lng
                      }
                      cover {
                          carousel
                          cover_background
                          title
                      }
                      experience {
                          securitization,
                          financing
                      }
                      footer {
                          lista_telefonos
                          map_url
                          address
                      }
                      navbar {
                          social_links {
                              instagram
                              linkedin
                              twitter
                              fb
                          }
                      }
                      services {
                          advisory
                          contracts
                      }
                      finance_structures {
                          description
                      }
                      history {
                          activos
                          clientes
                          concessions
                          description
                          fs
                          fs2
                          history_1_img
                          history_2_img
                          history_4_img
                          history_3_img
                          history_6_img
                          history_5_img
                          history_7_img
                          history_img
                          tenders
                          transport
                          transport2
                      }
                      we_are_part {
                          afisofom_desc
                          afiservicios_desc
                          mlp_desc
                      }
                      not_found {
                          text
                          _404_img
                          _404_img_responsive
                      }
                  }
              }
    `
  );

  return siteContent;
}
